
    @charset "UTF-8"; @use "sass:math"; @import "/src/_core/scss/core.scss"; @import "/src/global/scss/_abstracts.scss"; 
    
.accordion {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  &__item {
    display: flex;
    flex-direction: column;
  }

  &__trigger {
    all: unset;
    appearance: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 0.5rem 1.25rem;
    margin: 0;
    line-height: 1;
    cursor: pointer;
    position: relative;
    z-index: 0;

    &::before {
      content: '';
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: currentColor;
      filter: brightness(100%) grayscale(1) invert(1);
      transition: 0.2s ease;
    }

    &::after {
      content: '';
      height: 0.5rem;
      width: 0.5rem;
      border-bottom: 2px solid;
      border-right: 2px solid;
      transform: translateY(-2px) rotate(45deg);
      transition: 0.2s ease;
    }

    &:hover,
    &:focus,
    &[data-active] {
      &::before {
        filter: brightness(80%) grayscale(1) invert(1);
      }
    }

    &[data-active] {
      &::after {
        transform: rotate(-135deg);
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__content-inner {
    padding-top: 1rem;

    & > *:last-child {
      margin-bottom: 0;
    }
  }
}
  
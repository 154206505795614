
    @charset "UTF-8"; @use "sass:math"; @import "/src/_core/scss/core.scss"; @import "/src/global/scss/_abstracts.scss"; 
    .breadcrumbs {
  --gap: 0.75rem;
  --spacing: calc((var(--gap) * 2) + 1px);
  --adjust: calc(var(--spacing) * -1);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: var(--spacing) 0;
  gap: 1rem 1.5rem;

  &__nav {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    gap: 0.75rem 0;
  }

  &__nav-item {
    display: flex;
    align-items: center;
    color: $black;
    margin-right: var(--spacing);
    font-size: 0.75rem;
    line-height: 1;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    margin: 4px;
    margin-right: var(--spacing);
    white-space: nowrap;
    &:where(a) {
      &:hover {
        text-decoration: none;
        color: $linkColor;
        &::after {
          color: $gray;
        }
      }
    }
    &::before {
      display: flex;
      justify-content: center;
      width: var(--spacing);
      margin-left: var(--adjust);
      font-size: 1rem;
      color: $gray;
      content: '|';
      pointer-events: none;
      opacity: 0.25;
    }
    &:last-child {
      color: $gray;
    }
  }

  &__social {
    display: flex;
    align-items: center;
    gap: var(--gap);
  }

  &__social-item {
    font-size: 1.325rem;
    color: inherit;
    color: $gray;
    &:hover {
      text-decoration: none;
      color: $linkColor;
    }
  }
}

  

    @charset "UTF-8"; @use "sass:math"; @import "/src/_core/scss/core.scss"; @import "/src/global/scss/_abstracts.scss"; 
    .list-item {
  --gap: 0.25rem;
  --spacing: calc((var(--gap) * 4) + 1px);
  --adjust: calc(var(--spacing) * -1);

  display: flex;
  flex-direction: column;

  &__inner {
    display: flex;
    flex-direction: column;
    row-gap: 0.75rem;
    column-gap: 1.5rem;
    color: $fontColorBase;
    transition: none;
  }


  &:is([data-view="list"] *) {
    &:not(:last-child) > * {
      padding-bottom: 1.5rem;
      margin-bottom: 1.5rem;
      border-bottom: 1px solid $lightGray;
    }
  }

  &:is([data-view="grid"] *) {
    border: 1px solid $lightGray;
    padding: 1rem;
    .list-item__image {
      visibility: visible;
      max-height: none;
      max-width: none;
    }
  }

  &__image {
    display: flex;
    flex-direction: column;
    visibility: hidden;
    max-height: 0;
    max-width: 0;
    width: 100%;
  }

  &__meta {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    overflow: hidden;
    gap: var(--gap) 0;
  }

  &__meta-item {
    display: flex;
    align-items: center;
    font-size: 0.75rem;
    line-height: 1;
    margin-right: var(--spacing);
    white-space: nowrap;
    &::before {
      display: flex;
      justify-content: center;
      width: var(--spacing);
      margin-left: var(--adjust);
      font-size: 1rem;
      color: $gray;
      content: '|';
      pointer-events: none;
      opacity: 0.25;
    }
    &--bold {
      font-weight: $fontWeightBold;
      transition: 0.2s ease;
    }
  }

  &__title {
    font-weight: $fontWeightBold;
    margin: 0;
    transition: 0.2s ease;
  }

  &__link {
    text-decoration: none;
    color: $linkColor;
    
    &:hover {
      text-decoration: none;
      color: $linkColorHover;
    }
  }

  &__copy {
    font-size: 0.875rem;
    margin: 0;
    line-height: 1.5;
  }

}
  
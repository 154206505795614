
    @charset "UTF-8"; @use "sass:math"; @import "/src/_core/scss/core.scss"; @import "/src/global/scss/_abstracts.scss"; 
    .footer {
  display: flex;
  flex-direction: column;
  &__inner {
    display: flex;
    flex-direction: column;
    color: $lightGray;
    background: $darkGray;
  }

  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    border-top: 1px solid $gray;
    border-bottom: 1px solid $gray;
    &-img {
      max-width: 322px;
    }
  }

  &__main {
    @include container;
    display: flex;
    flex-direction: column;
    @include mq(md) {
      flex-direction: row;
      gap: 1px;
    }
  }

  &__nav,
  &__contact {
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
    padding-bottom: 2rem;
    @include mq(md) {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
    &:first-child {
      @include mq(md) {
        padding-right: 3rem;
      }
    }
    &:last-child {
      @include mq(md) {
        padding-left: 3rem;
      }
    }
  }

  &__nav {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    @include mq(md) {
      justify-content: space-between;
      flex-direction: row;
      border-right: 1px solid $gray;
      flex: 1 1 100%;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    font-size: 1.125rem;
    text-transform: uppercase;
    color: $blue;
    cursor: default;
    font-weight: 700;
    @include mqdn(md) {
      text-align: center;
      justify-content: center;
    }
    .footer__nav & {
      @include mqdn(md) {
        gap: 0.5rem;
        cursor: pointer;

        &::after {
          content: '';
          height: 0.4rem;
          width: 0.4rem;
          border-left: 2px solid $blue;
          border-bottom: 2px solid $blue;
          transform: translateY(-50%) rotate(-45deg);
          transition: 0.25s ease;
        }

        &[data-active] {
          &::after {
            transform: translateY(0) rotate(135deg);
          }
        }
      }
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    gap: 0.5rem;
    @include mq(md) {
      margin-right: auto;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0;
    margin: 0;
    gap: 0.5rem;
    list-style: none;
    overflow: hidden;
    height: 0;
    opacity: 0;
    visibility: hidden;
    &[data-active] {
      height: auto;
      opacity: 1;
      visibility: visible;
    }
    @include mqdn(md) {
      & > *:first-child {
        padding-top: 0.5rem;
      }
      & > *:last-child {
        padding-bottom: 1rem;
      }
    }
    @include mq(md) {
      align-items: stretch;
      text-align: left;
      margin-right: auto;
      overflow: initial;
      height: auto;
      opacity: 1;
      visibility: visible;
    }
  }

  &__item {
    display: flex;
  }

  &__link {
    font-size: 1rem;
    color: $lightGray;
    cursor: pointer;
    flex: 0 0 auto;
    transition: 0.2s ease;
    &:hover {
      text-decoration: none;
      color: $white;
    }

    .footer__nav & {
      margin-left: -1rem;
      margin-right: -0.25rem;
      border-left: 0.5rem solid transparent;
      padding-left: 0.5rem;
      padding-right: 0.25rem;

      &:focus,
      &:hover {
        border-left-color: $blue;
        color: $white;
        text-decoration: none;
        text-shadow: 1px 0 0 currentColor;
      }
    }
  }

  &__contact {
    gap: 2rem;
    position: relative;
    @include mqdn(md) {
      &::before {
        position: absolute;
        content: '';
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100vw;
        height: 1px;
        background: $darkGray;
      }
    }
    @include mq(md) {
      flex: 0 0 auto;
      min-width: 30%;
    }
  }

  &__form {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto;
    gap: 1rem 0;
    width: 100%;
    max-width: 350px;
    margin: 0 auto;
    @include mq(md) {
      gap: 1rem 0.5rem;
      margin: 0;
    }

    .footer__title {
      flex: 0 0 100%;
      grid-area: 1 / 1 / 2 / 3;
    }
  }

  &__email {
    border: none;
    padding: 0.75rem;
    font-size: 0.875rem;
    grid-area: 2 / 1 / 3 / 2;
    width: auto;
    max-width: 100%;
    min-width: 0;
  }

  &__submit {
    grid-area: 2 / 2 / 3 / 3;
  }

  &__social {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-template-rows: auto auto;
    gap: 1rem 1.5rem;
    justify-content: center;
    @include mq(md) {
      justify-content: start;
      grid-template-columns: auto auto auto auto 1fr;
    }

    .footer__title {
      grid-area: 1 / 1 / 2 / 5;
      @include mq(md) {
        grid-area: 1 / 1 / 2 / 6;
      }
    }

    .footer__link {
      display: flex;
      align-self: center;
      justify-content: center;
      align-items: center;
      grid-row: 2 / 3;
      grid-column: span 1;

      i {
        font-size: 1.5rem;
        transition: 0.2s ease;
      }

      &:hover {
        i {
          color: $blue;
        }
      }
    }
  }

  &__meta {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 3rem;
    gap: 0.5rem 2rem;
    border-top: 1px solid $gray;
    text-align: center;
    font-size: 1rem;
    @include mq(md) {
      text-align: left;
      flex-direction: row;
    }
  }

  &__copy {
    @include mq(md) {
      margin-right: auto;
    }
  }

  &__legal {
    display: flex;
    align-items: center;
    gap: 2rem;
    a {
      &:hover {
        color: $blue;
      }
    }
  }
}

  

    @charset "UTF-8"; @use "sass:math"; @import "/src/_core/scss/core.scss"; @import "/src/global/scss/_abstracts.scss"; 
    .header {
  display: flex;
  flex-direction: column;
  background: $darkGray;
  color: $white;
  z-index: 10;
  &.is-open {
    @include mqdn(sm) {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
    }
  }

  &__inner {
    @include container($margin: 1.5rem);
    display: grid;
    grid-template-columns: 1fr auto auto;
    grid-template-rows: auto auto;
    gap: 0.5rem 0.25rem;
    padding-top: 0.5rem;
    padding-bottom: 1rem;
    position: relative;

    @include mq(sm) {
      grid-template-columns: minmax(225px, max-content) 1fr minmax(175px, 40%);
      grid-template-rows: minmax(40px, auto) auto auto;
      gap: 1rem 2rem;
      padding-top: 0;
      padding-bottom: 0;
    }

    @include mq(lg) {
      grid-template-columns: max-content 1fr max-content;
      grid-template-rows: minmax(40px, auto) auto;
    }
  }

  &__logo {
    display: flex;
    grid-area: 1 / 1 / 2 / 2;
    max-width: 370px;
    @include mq(sm) {
      grid-area: 2 / 1 / 3 / 2;
    }
    @include mq(lg) {
      padding-bottom: 1rem;
    }

    &-link {
      display: flex;
      align-items: center;
      height: auto;
      width: auto;
    }

    &-img {
      width: 100%;
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__util {
    display: none;
    justify-content: flex-end;
    grid-area: 1/2/2/3;
    position: relative;
    z-index: 2;
    background: darken($darkGray, 10%);
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      bottom: 0;
      width: 100vw;
      transform: translateX(-50%);
      background: inherit;
    }
    @include mq(sm) {
      display: flex;
      grid-area: 1 / 1 / 2 / 4;
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100vw;
        height: 1px;
        background: $darkGray;
        z-index: -2;
      }
    }

    &-list {
      display: flex;
      padding: 0;
      margin: 0;
      list-style: none;
    }

    &-item {
      display: flex;
      position: relative;
      @include mq(sm) {
        border-left: 1px solid $darkGray;
        &:last-child {
          .header__util-label {
            margin-right: -1rem;
            padding-right: 1rem;
          }
        }

        body:not(.using-mouse) &:focus-within {
          .header__util-label {
            #{$focusProperty}: $focusValue;
          }
        }
      }
    }

    &-label {
      display: flex;
      align-items: center;
      cursor: pointer;
      color: $white;
      transition: 0.2s ease;
      padding: 0;
      align-self: center;
      @include mq(sm) {
        align-self: stretch;
        gap: 0.75rem;
        font-size: 0.875rem;
        padding: 0.5rem 1.5rem;
        i {
          font-size: 1rem;
        }
      }

      &:hover {
        text-decoration: none;
        color: $white;
        background: $blue;
      }

      &:where(button) {
        appearance: none;
        border: none;
        background: transparent;
        @include mq(sm) {
          position: relative;
          &::after {
            content: '';
            height: 0.4rem;
            width: 0.4rem;
            border-left: 2px solid currentColor;
            border-bottom: 2px solid currentColor;
            transform: translateY(-25%) rotate(-45deg);
            transition: opacity 0.2s ease, transform 0.2s ease;
            .is-active & {
              opacity: 1;
              transform: rotate(135deg);
            }
          }
        }
      }
    }

    &-menu {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      position: absolute;
      top: 100%;
      right: 0;
      min-width: 100%;
      z-index: -1;
      overflow: hidden;
      transform: translateY(-1rem);
      opacity: 0;
      visibility: hidden;
      transition: 0.2s ease;
      pointer-events: none;
      background: $lightGray;
      padding: 1rem;
      box-shadow: 0 0 0.25rem rgba($darkGray, 0.35),
        0 0 0.5rem rgba($darkGray, 0.05);

      .is-active & {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
        transform: translateY(0);
      }

      &-link {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        align-items: center;
        font-size: 0.75rem;
        text-transform: uppercase;
        color: black;
        white-space: nowrap;
        margin-left: -0.75rem;
        margin-right: -0.25rem;
        border-left: 0.375rem solid transparent;
        padding-left: 0.375rem;
        padding-right: 0.25rem;

        &:focus,
        &:hover {
          border-left-color: $blue;
          color: $darkGray;
          text-decoration: none;
          i::before {
            transform: translateX(0.5rem);
          }
        }

        i::before {
          transition: 0.2s ease;
        }
      }
    }
  }

  &__toggle {
    grid-area: 1/3/2/4;
    display: flex;
    justify-self: end;
    align-items: center;
    align-self: center;
    cursor: pointer;
    appearance: none;
    border: none;
    background: transparent;
    padding: 0;
    height: 1.75rem;
    width: 1.75rem;
    margin-left: 0.25rem;
    position: relative;
    @include mq(sm) {
      display: none;
    }

    body:not(.using-mouse) &:focus {
      #{$focusProperty}: none;
      box-shadow: inset 0 0 0 0.125rem $blue;
    }
    &:hover {
      i::before {
        color: $white;
      }
    }
    i {
      &::before {
        display: block;
        font-size: 1.75rem;
        color: $white;
        transition: 0.2s ease;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        transition: 0.2s ease;
      }
      &:last-of-type::before {
        font-size: 1.5rem;
        opacity: 0;
        left: 4px;
        bottom: 2px;
      }
    }

    &.is-open {
      i {
        &::before {
          transform: rotate(180deg);
        }
        &:first-of-type::before {
          opacity: 0;
        }
        &:last-of-type::before {
          opacity: 1;
        }
      }
    }
  }

  &__nav {
    display: none;
    @include mq(sm) {
      grid-area: 3 / 1 / 4 / 4;
      display: flex;
      flex-direction: column;
      &::before {
        content: '';
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        width: 100vw;
        height: 1px;
        background: lighten($darkGray, 10%);
      }
    }
    @include mq(lg) {
      grid-area: 2 / 2 / 3 / 3;
      &::before {
        display: none;
      }
    }

    &-list {
      list-style: none;
      padding: 0;
      margin: 0;
      @include mq(sm) {
        display: flex;
        flex: 1 1 100%;
        justify-content: space-around;        
      }
      @include mq(lg) {
        flex: 1 1 100%;
        justify-content: space-evenly;
      }
    }

    &-item {
      @include mq(sm) {
        display: flex;
        &--single {
          position: relative;         
        }       
      }
    }

    &-label {
      @include mq(sm) {
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;
        appearance: none;
        border: none;
        background: transparent;
        font-size: 1.125rem;
        color: $white;
        transition: 0.2s ease;
        padding: 0.5rem 0;

        .is-active &,
        body:not(.using-mouse) &:focus {
          #{$focusProperty}: unset;
          span {
            #{$focusProperty}: $focusValue;
          }
        }
      }
      @include mq(lg) {
        padding: 0;
      }
      span {
        @include mq(sm) {
          display: flex;
          align-items: center;
          position: relative;
          padding: 0.25rem;
          gap: 0.75rem;
        }
        @include mq(lg) {
          padding: 0.5rem;
        }
        &::after {
          @include mq(sm) {
            content: '';
            height: 0.6rem;
            width: 0.6rem;
            border-left: 3px solid $white;
            border-bottom: 3px solid $white;
            transform: translateY(-25%) rotate(-45deg);
            transition: 0.2s ease;
            .is-active & {
              opacity: 1;
              transform: rotate(135deg);
            }
          }
        }
      }

      &::before {
        @include mq(sm) {
          content: '';
          position: absolute;
          bottom: -1px;
          left: 50%;
          transform: translateX(-50%) translateY(-1rem);
          height: 0;
          width: 0;
          border-left: 0.7rem solid transparent;
          border-right: 0.7rem solid transparent;
          border-bottom: 0.6rem solid $lightGray;
          opacity: 0;
          transition: 0.2s ease;
          .is-active & {
            opacity: 1;
            transform: translateX(-50%);
          }
        }
      }
      &--empty {
        &:focus,
        &:hover {
          text-decoration: none;
          color: $white;
          text-shadow: 1px 0 0 currentColor;
          background: $blue;
        }      
        @include mq(sm) {
          display: flex;
          align-items: center;
          position: relative;
          padding: 0.25rem;
          gap: 0.75rem;
        }
        @include mq(lg) {
          padding: 0.5rem;
        }        
      }      
    }

    &-menu {
      @include mq(sm) {
        display: flex;
        position: absolute;
        top: 100%;
        left: 50%;
        color: $darkGray;
        z-index: -1;
        overflow: hidden;
        transform: translateX(-50%) translateY(-1rem);
        opacity: 0;
        visibility: hidden;
        transition-property: opacity, visibility, transform;
        transition-duration: 0.2s;
        transition-timing-function: ease;
        pointer-events: none;
        background: $lightGray;
        padding: 1.5rem 2rem;
        box-shadow: 0 0 0.25rem rgba($darkGray, 0.35),
          0 0 0.5rem rgba($darkGray, 0.05);

        .is-active & {
          opacity: 1;
          visibility: visible;
          transform: translateX(-50%);
          pointer-events: all;
        }

        .header__nav-item--full & {
          width: 100%;
          max-width: 1000px;
        }
        .header__nav-item--feature & {
          width: 100%;
          max-width: 700px;
        }
      }
    }

    &-back {
      @include mq(sm) {
        display: none;
      }
    }

    &-grid {
      @include mq(sm) {
        width: calc(100% + 0.5rem);
        margin: -0.25rem;
        padding: 0.25rem;
      }

      .header__nav-item--full & {
        @include mq(sm) {
          display: grid;
          grid-auto-rows: 2px;
          justify-content: center;
          grid-auto-flow: column;
          position: relative;
          z-index: 0;
          overflow: hidden;
          grid-template-columns: repeat(3, minmax(100px, max-content));
          gap: 1rem 2rem;
        }
        @include mq(lg) {
          grid-auto-flow: row;
          grid-template-columns: repeat(4, minmax(100px, max-content));
          gap: 1rem 3rem;
        }
      }

      .header__nav-item--feature & {
        @include mq(sm) {
          display: flex;
        }
      }

      &-cell {
        @include mq(sm) {
          display: flex;
          flex-direction: column;
          padding: 0;
          margin: 0;

          .header__nav-item--feature & {
            flex: 1 0 auto;
            padding: 0 2rem;
            border-right: 1px solid darken($lightGray, 5%);
            &:first-child {
              padding-left: 0;
            }
            &:last-child {
              border-right: none;
              padding-right: 0;
              flex: 0 1 450px;
            }
          }

          .header__nav-item--full & {
            position: relative;
            padding-right: 1rem;
            &:not(.is-last-column)::after {
              content: '';
              position: absolute;
              top: -100vh;
              right: -1rem;
              height: 200vh;
              width: 1px;
              background: darken($lightGray, 5%);
              z-index: -2;
            }
          }
        }
      }
      &-content {
        @include mq(sm) {
          display: flex;
          font-size: 1rem;
        }
      }
    }
    &-group {
      @include mq(sm) {
        display: flex;
        flex-direction: column;
        flex: 1 1 100%;
      }
      &-title {
        appearance: none;
        border: none;
        background: none;
        text-align: left;
        @include mq(sm) {
          text-transform: uppercase;
          margin: 0;
          color: $darkGray;
          padding: 0;
          &:is(a) {
            &:focus,
            &:hover {
              text-decoration: none;
              color: $blue;
            }
          }
        }
      }
      &-list {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
        list-style: none;
        @include mq(sm) {
          gap: 0.5rem;
        }
      }
      &-item {
        display: flex;
        margin: 0;
        padding: 0;
        @include mq(sm) {
          line-height: 1.5;
          &--feature {
            white-space: nowrap;
          }
        }
      }
      &-link {
        @include mq(sm) {
          margin: 0;
          padding: 0;
          color: $darkGray;
          margin-left: -1rem;
          margin-right: -0.25rem;
          border-left: 0.5rem solid transparent;
          padding-left: 0.5rem;
          padding-right: 0.25rem;
          transition: 0.2s ease border-left;
          .header__nav-item--single & {
            white-space: nowrap;
          }
          &:focus,
          &:hover {
            border-left-color: $blue;
            color: $darkGray;
            text-decoration: none;
            text-shadow: 1px 0 0 currentColor;
          }
        }
      }
    }
  }

  &__tools {
    display: flex;
    gap: 0.5rem;
    align-self: center;
    justify-content: flex-end;
    grid-area: 2 / 1 / 3 / 4;
    @include mq(sm) {
      grid-area: 2 / 3 / 3 / 4;
    }
  }

  &__search {
    display: flex;
    flex: 1 1 100%;

    @include mq(lg) {
      max-width: 350px;
      min-width: 200px;
    }

    &-wrapper {
      display: flex;
      width: 100%;
      background: $white;
    }

    &-input {
      appearance: none;
      border: none;
      background: transparent;
      border-radius: 0;
      padding: 0.3875rem 0 0.3875rem 0.75rem;
      flex: 1 1 100%;
      font-size: 1rem;
      width: auto;
      min-width: 0;
      &::placeholder {
        font-size: 0.875rem;
      }
      @include mq(sm) {
        padding: 0.625rem 0 0.625rem 1rem;
      }
    }

    &-submit {
      appearance: none;
      border: none;
      background: transparent;
      border-radius: 0;
      padding: 0 0.5rem;
      font-size: 0.875rem;
      color: $fontColorBase;
      @include mq(sm) {
        font-size: 1rem;
      }
    }
  }

  &__drawer {
    display: flex;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: $darkGray;
    color: $white;
    transform: translateX(100%);
    transition-duration: 0.2s;
    transition-timing-function: ease;
    transition-property: transform, opacity, visibility;
    z-index: 100;
    opacity: 0;
    visibility: hidden;
    border-top: 1px solid $darkGray;

    &.is-open {
      transform: translateX(0);
      opacity: 1;
      visibility: visible;
    }

    @include mq(sm) {
      display: none;
    }

    &-inner {
      display: flex;
      flex: 0 0 100%;
      position: relative;
    }

    &-list {
      display: flex;
      flex-direction: column;
      flex: 0 0 100%;
      list-style: none;
      padding: 0;
      margin: 0;
      flex: 0 0 100%;
      overflow-y: auto;
    }

    &-item {
      display: flex;
      font-size: 0.875rem;
      position: static;
    }

    .header {
      &__util,
      &__nav {
        &-back {
          display: flex;
          align-items: center;
          gap: 0.75rem;
          appearance: none;
          border: none;
          background: darken($black, 100%);
          padding: 1rem 1.5rem;
          color: $white;
          justify-content: start;
          text-align: left;
          font-size: 0.875rem;
          cursor: pointer;
          width: 100%;
          &::before {
            content: '';
            height: 0.6rem;
            width: 0.6rem;
            border-left: 2px solid $white;
            border-top: 2px solid $white;
            transform: translateY(-15%) rotate(-45deg);
          }
          body:not(.using-mouse) & {
            &:focus {
              #{$focusProperty}: none;
              box-shadow: inset 0 0 0 0.125rem $blue;
            }
          }
          span {
            pointer-events: none;

            &.header__util-back-link {
              text-transform: uppercase;
              font-size: 0.75rem;
              margin-left: auto;
              color: $blue;
              &:hover {
                text-decoration: none;
              }
            }
          }
        }
        &-item,
        &-group {
          &.is-active {
            & > .header__nav-menu,
            & > .header__util-menu,
            & > .header__nav-group-list {
              transform: translateX(0);
              opacity: 1;
              visibility: visible;
            }
          }
        }
        &-label,
        &-menu-link,
        &-group-title,
        &-group-link {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          appearance: none;
          border: none;
          background: transparent;
          color: $white;
          padding: 0.75rem 1.75rem 0.75rem 1.5rem;
          margin: 0;
          cursor: pointer;
          font-size: 0.875rem;
          text-transform: none;

          body:not(.using-mouse) & {
            &:focus {
              #{$focusProperty}: none;
              box-shadow: inset 0 0 0 0.125rem $blue;
            }
          }
          i {
            display: none;
          }

          &:hover {
            text-decoration: none;
            color: $white;
            color: $blue;
          }

          &:where(.header__nav-label):not(.header__nav-label--empty),
          &:where(.header__util-label),
          &:where(.header__nav-group-title) {
            &::after {
              content: '';
              height: 0.6rem;
              width: 0.6rem;
              border-right: 2px solid $white;
              border-bottom: 2px solid $white;
              transform: translateY(-25%) rotate(-45deg);
            }
          }
        }
        &-menu,
        &-group-list:is(.header__nav-item--full *) {
          display: flex;
          flex-direction: column;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 1;
          background: $darkGray;
          transition: 0.2s ease;
          transform: translateX(100%);
          opacity: 0;
          visibility: hidden;
          overflow-y: auto;
          gap: 0;
          padding: 0;
          box-shadow: none;
        }
        &-grid,
        &-grid-cell {
          display: contents;
        }
        &-grid-cell--feature {
          display: none;
        }
      }

      &__util {
        &-item {
          border-top: 1px solid $darkGray;
          &:last-child {
            border-bottom: 1px solid $darkGray;
          }
          a + button {
            display: none;
          }
        }
      }
    }
  }
}

  
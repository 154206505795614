
    @charset "UTF-8"; @use "sass:math"; @import "/src/_core/scss/core.scss"; @import "/src/global/scss/_abstracts.scss"; 
    .wysiwyg{
    &-media {
    max-width: none;
    padding: 0 0 1rem 0;
    clear: both;
    margin: 0 auto;
    @include mq(md) {
        max-width: 700px;
    }
    
    img {
        margin: 0;
    }
        &--floatRight,
        &--floatLeft {
            text-align: unset;
            margin: unset;
            @include mq(md) {
                max-width: 500px;
            }
        }
        &--floatRight {
            @include mq(md) {
                padding-left: 2rem;
                float: right;
            }
        }
        &--floatLeft {
            @include mq(md) {
                padding-right: 2rem;    
                float: left;
            }
        }
        &__caption {
            font-size: .875rem;
        }
    }
}
    
blockquote {
        padding: 0 3rem;
        position: relative;
        @include mqdn(sm) {
            padding: 0 1.375rem;
        }
        p {
            font-size: 1.375rem;
            &::before,
            &::after {
                position: absolute;
                top: 0;
                line-height: 1;
                font-size: 4rem;
                @include mqdn(sm) {
                    font-size: inherit;
                    position: static;
                }
            }
            &::after {
                right: 0;
            }
            &::before {
                left: 0;
            }
        }
        cite span {
            font-weight: 400;
        }
    }

  
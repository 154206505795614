
    @charset "UTF-8"; @use "sass:math"; @import "/src/_core/scss/core.scss"; @import "/src/global/scss/_abstracts.scss"; 
    .app-title {
  display: flex;
  flex-direction: column;

  &__inner {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: max-content;
    position: relative;
    z-index: 0;
    &::before {
      content: '';
      z-index: -1;
      pointer-events: none;
      position: relative;
      padding-top: calc((5 / 14) * 100%);
      grid-area: 1 / 1 / 2 / 2;
    }
  }

  &__background {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;

    &::after {
      content: '';
      z-index: 2;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: $black;
      mix-blend-mode: soft-light;
      isolation: isolate;
    }
  }

  &__background-image {
    height: 100%;
    width: 100%;
    z-index: 1
  }

  &__content {
    @include fluid(gap, 1.25rem, 1.75rem);
    @include fluid(padding, 1.5rem, 2.25rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    grid-area: 1 / 1 / 2 / 2;
    color: $white;
  }

  &__heading,
  &__subheading {
    max-width: 800px;
    margin: 0;
  }

  &__heading {
    @include fluid(font-size, 1.75rem, 2.75rem);
  }

  &__subheading {
    @include fluid(font-size, 0.875rem, 1.125rem);
  }

  &__outer {
    @include fluid(padding, 0.875rem, 1.125rem);
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0 1.5rem;
    width: 100%;
    background: $lightGray;
    color: $darkGray;
    font-size: 0.875rem;
  }

  &__author {
    margin: 0;
    font-weight: 700;
    line-height: 1;
  }
  
  &__date {
    margin: 0;
    line-height: 1;
    font-style: italic;
  }
}
  
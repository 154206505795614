
    @charset "UTF-8"; @use "sass:math"; @import "/src/_core/scss/core.scss"; @import "/src/global/scss/_abstracts.scss"; 
    .list-tools {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 1rem;
  padding: 0 0 2rem;
  border-bottom: 1px solid $lightGray;

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.25rem;
    line-height: 1;
    &:first-child:not(:only-child) {
      margin-right: auto;
      align-items: flex-start;
    }
  }

  &__label {
    font-weight: $fontWeightBold;
    font-size: 0.75rem;
    padding: 0 0.25rem;
    opacity: 0.5;
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }

  // Removed because setting this dynamically per user is causing CLS
  // &__view {
  //   display: flex;
  //   margin-right: auto;
  //   gap: 1rem;

  //   &-button {
  //     all: unset;
  //     appearance: none;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     line-height: 1;
  //     cursor: pointer;
  //     font-size: 1.5rem;
  //     padding: 0.25rem 0.25rem 0.125rem;
  //     background: $lightGray;
  //     transition: 0.2s ease;

  //     &:hover {
  //       background: darken($lightGray, 10%);
  //     }

  //     &[data-view="grid"] {
  //       &:is([data-view="grid"] *) {
  //         background: $linkColor;
  //         color: $white;
  //       }
  //     }

  //     &[data-view="list"] {
  //       &:is([data-view="list"] *) {
  //         background: $linkColor;
  //         color: $white;
  //       }
  //     }
  //   }
  // }

  &__select {
    display: flex;
    position: relative;
    min-width: 175px;

    &-active {
      all: unset;
      appearance: none;
      flex: 1 1 100%;
      display: flex;
      justify-content: space-between;
      gap: 0.5rem;
      padding: 0.5rem 1rem;
      border: 1px solid darken($lightGray, 10%);
      cursor: pointer;
      font-weight: $fontWeightBold;
      &::after {
        content: '';
        height: 0.3875rem;
        width: 0.3875rem;
        border-right: 2px solid currentColor;
        border-bottom: 2px solid currentColor;
        transform: translateY(10%) rotate(45deg);
        transition: 0.2s ease;
      }

      &[data-active] {
        &::after {
          transform: translateY(30%) rotate(-135deg);
        }
      }
    }

    &-menu {
      display: flex;
      flex-direction: column;
      background: $white;
      z-index: 10;
      position: absolute;
      top: calc(100% + 0.75rem);
      left: 0;
      right: 0;
    }

    &-inner {
      display: flex;
      flex-direction: column;
      list-style: none;
      margin: 0;
      padding: 0;
      border: 1px solid darken($lightGray, 10%);
      overflow-y: auto;
      max-height: 200px;
    }

    &-item {
      display: flex;
      flex-direction: column;
    }

    &-link {
      display: flex;
      justify-content: space-between;
      gap: 0.75rem;
      font-size: 0.875rem;
      color: $fontColorBase;
      transition: 0.2s ease;
      padding: 0.3875rem 0.75rem;

      &::after {
        content: '';
        height: 0.5rem;
        width: 0.25rem;
        border-right: 2px solid currentColor;
        border-bottom: 2px solid currentColor;
        transform: translateY(10%) rotate(45deg);
        opacity: 0;
        transition: 0.2s ease;
      }

      &:hover {
        background: $lightGray;
        text-decoration: none;
        &::after {
          opacity: 0.25;
        }
      }

      &--active,
      &--active:hover {
        color: $linkColor;
        font-weight: $fontWeightBold;
        &::after {
          opacity: 1;
        }
      }
    }
  }
}
  

    @charset "UTF-8"; @use "sass:math"; @import "/src/_core/scss/core.scss"; @import "/src/global/scss/_abstracts.scss"; 
    .alert {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  background: darken($darkGray, 10%);
  height: auto;
  &.hidden {
    pointer-events: none;
    grid-template-rows: 0fr;
    transition: grid-template-rows .4s ease; 
  }  
  &--is-static {
    .alert__close {
      display: none;
    }
  }

  &__track {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background: $blue;
    animation: slideInFromAbove 500ms ease;
  }

  &__inner {
    display: grid;
    grid-template-columns:
      minmax(0, 1fr) minmax(min-content, max-content)
      minmax(0, 1fr);
    gap: 1rem;
    padding: 0.85rem 1.5rem 0.85rem 0.85rem;
    flex: 1 1 100%;
  }

  &__content {
    grid-area: 1 / 2 / 2 / 3;
    color: $white;
    font-family: $fontWeightHeadings;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      & * {
        color: inherit;
        margin: 0;

        &:where(a) {
          text-decoration: underline;
        }
      }
    }
  }

  &__close {
    grid-area: 1 / 3 / 2 / 4;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: end;
    padding: 0;
    appearance: none;
    background: none;
    border: none;
    color: $white;
    transition: 0.2s ease;
    cursor: pointer;
    margin-bottom: -2px;
    font-size: 1.5rem;

    &:hover,
    &:focus {
      transform: rotate(90deg);
    }
  }
}

  
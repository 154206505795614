
    @charset "UTF-8"; @use "sass:math"; @import "/src/_core/scss/core.scss"; @import "/src/global/scss/_abstracts.scss"; 
    .feature {
  display: flex;
  flex-direction: column;

  @include mq(lg) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &--60-40 {
    grid-template-columns: 60% 40%;
  }

  &--40-60 {
    grid-template-columns: 40% 60%;
  }

  &:not(.feature--flipped) {
    .feature__media {
      @include mq(lg) {
        grid-column-start: 1;
      }
    }
    .feature__content {
      @include mq(lg) {
        grid-column-start: 2;
      }
    }
  }
  &--flipped {
    .feature__media {
      @include mq(lg) {
        grid-column-start: 2;
      }
    }
    .feature__content {
      @include mq(lg) {
        grid-column-start: 1;
      }
    }
  }

  &--card,
  &--nav {
    @include mq(lg) {
      display: flex;
      flex-direction: column;
    }
    .feature__content {
      background: $lightGray;
      color: $fontColorBase;
    }
  }

  &--card {
    .feature__media {
      min-height: 0;
    }

    .feature__content {
      flex-grow: 1;
      justify-content: start;
    }

    .feature__copy {
      flex-grow: 1;
    }

    &:is(a) {
      cursor: pointer;
  
      &:hover {
        text-decoration: none;
  
        .feature__image {
          opacity: .9;
        }
      }
  
      &:focus {
        outline: none;
      }
    }
  }

  &--nav {
    max-width: 100%;
    gap: 1.5rem;
  }

  &__media,
  &__content {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    @include mq(lg) {
      grid-column-end: span 1;
      grid-row: 1 / span 1;
    }
  }

  &__media {
    min-height: 100%;
    position: relative;
    .feature--nav & {
      min-height: 0;
    }
    &::before {
      content: '';
      display: block;
      position: relative;
      padding-top: calc((9 / 16) * 100%);
      pointer-events: none;
    }
  }

  &__image,
  &__video {
    position: absolute;
    inset: 0;
    object-fit: cover;
    object-position: 50% 50%;
    height: 100%;
    width: 100%;
    border: none;
    transition: 0.2s ease;
    max-width: 100%;

    * {
      max-width: 100%;
    }
  }

  &__image {
    * {
      object-fit: cover;
      object-position: 50%;
      max-width: 100%;
      height: 100%;
      width: 100%;
    }
  }

  &__content {
    @include fluid(padding, 1.5rem, 2rem);
    align-items: flex-start;
    justify-content: center;
    .feature--nav & {
      padding: 0;
      background: none;
      color: inherit;
    }
  }

  &__title {
    @include fluid(font-size, 1.5rem, 2.25rem);
    line-height: 1.25;
    margin: 0 0 0.75rem;
    .feature--nav,
    .feature--card & {
      @include fluid(font-size, 1.25rem, 1.75rem);
    }
  }

  &__copy {
    @include wysiwyg;
    @include fluid(font-size, 0.75rem, 1rem);
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    margin: 0;
    line-height: 1.5;
    .feature--nav,
    .feature--card & {
      @include fluid(font-size, 0.75rem, 0.875rem);
    }

    & > * {
      margin: 0;
    }
  }

  &__cta {
    @include fluid(font-size, 0.75rem, 1rem);
    margin: 0.5rem 0 0;
    .feature--nav,
    .feature--card & {
      @include fluid(font-size, 0.75rem, 0.875rem);
    }
  }
}

  
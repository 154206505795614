.glyph-loading {
  @extend %glyph-loading;
}

.glyph-list:before {
  content: '\e91b';
}
.glyph-grid:before {
  content: '\e91d';
}
.glyph-mail:before {
  content: '\e91a';
}
.glyph-check:before {
  content: '\e919';
}
.glyph-alert:before {
  content: '\e900';
}
.glyph-arrow-down:before {
  content: '\e901';
}
.glyph-arrow-left:before {
  content: '\e902';
}
.glyph-arrow-right:before {
  content: '\e903';
}
.glyph-arrow-up:before {
  content: '\e904';
}
.glyph-chevron-down:before {
  content: '\e905';
}
.glyph-chevron-left:before {
  content: '\e906';
}
.glyph-chevron-right:before {
  content: '\e907';
}
.glyph-chevron-up:before {
  content: '\e908';
}
.glyph-facebook:before {
  content: '\e909';
}
.glyph-filter:before {
  content: '\e90a';
}
.glyph-help:before {
  content: '\e90b';
}
.glyph-house:before {
  content: '\e90c';
}
.glyph-instagram:before {
  content: '\e90d';
}
.glyph-linkedin:before {
  content: '\e90e';
}
.glyph-menu-dots:before {
  content: '\e90f';
}
.glyph-menu-lines:before {
  content: '\e910';
}
.glyph-pause:before {
  content: '\e911';
}
.glyph-play:before {
  content: '\e912';
}
.glyph-reddit:before {
  content: '\e913';
}
.glyph-remove:before {
  content: '\e914';
}
.glyph-search:before {
  content: '\e915';
}
.glyph-snapchat:before {
  content: '\e916';
}
.glyph-twitter:before {
  content: '\e917';
}
.glyph-youtube:before {
  content: '\e918';
}
.glyph-user:before {
  content: '\e91c';
}
.glyph-upload:before {
  content: '\e95f';
}
